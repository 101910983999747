import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table, Tag } from "antd";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { toast } from "react-toastify";
import qs from "qs";

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const Customfields = () => {
  const [data, setData] = useState([]);
  const [editCustomId, setEditCustomId] = useState(null);
  const [custom, setCustom] = useState({
    title: "",
    description: "",
    type: "",
  });

  const [showInTable, setShowInTable] = useState(false); // State variable for the checkbox

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const [searchQuery, setSearchQuery] = useState("");

  // Function to filter data based on the search query
  const searchContacts = () => {
    return data.filter((customfield) => {
      return (
        customfield.title &&
        customfield.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false); // Track the modal visibility

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/contact/customfield?${qs.stringify(
          getRandomuserParams(tableParams)
        )}`
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleChangeCustom = (e) => {
    const { name, value } = e.target;
    setCustom({
      ...custom,
      [name]: value,
    });
  };

  const handleEditCustom = (_id) => {
    const selectedCustom = data.find((customid) => customid._id === _id);

    if (selectedCustom) {
      setEditCustomId(_id); // Set the ID of the contact being edited
      setCustom({
        title: selectedCustom.title,
        description: selectedCustom.description,
        type: selectedCustom.type,
      }); // Populate the form fields with the selected contact data
    }

    setIsModalOpen(true);

    // console.log(`Editing Custom with ID: ${_id}`);
  };

  const handleModalSave = (e) => {
    e.preventDefault();
    if (
      custom.title.trim() === "" ||
      custom.description.trim() === "" ||
      custom.type.trim() === ""
    ) {
      toast.error("Please fill in all the fields.");
      return;
    }

    const customData = {
      ...custom,
      showintable: showInTable, // Include the checkbox value
    };

    if (editCustomId) {
      axios
        .put(`/api/contact/customfield/edit/${editCustomId}`, customData)
        .then((res) => {
          setCustom({
            title: "",
            description: "",
            type: "",
          });
          setShowInTable(false);

          Swal.fire({
            title: "Customfield Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Customfield. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/contact/customfield/new", customData)
        .then((res) => {
          setCustom({
            title: "",
            description: "",
            type: "",
          });
          setShowInTable(false);

          Swal.fire({
            title: "Customfield Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Customfield. Please try again.",
            "error"
          );
        });
    }
  };

  const handleAddCustomFieldButton = () => {
    setEditCustomId(null);

    setCustom({
      title: "",
      description: "",
      type: "",
    });
  };

  const handleDeleteCustom = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/contact/customfield/delete/${_id}`)
        .then((res) => {
          // toast.success("Custom Field deleted successfully!");
          fetchData();
          Swal.fire("Deleted!", "CustomField deleted successfully!", "success");
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      color: "blue",
      ellipsis: true,
      render: (type) => {
        let color = "default";

        // Set different colors based on type
        switch (type) {
          case "string":
            color = "blue";
            break;
          case "number":
            color = "green";
            break;
          case "date":
            color = "orange";
            break;
          case "datetime":
            color = "purple";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color} key={type}>
             {type ? type.toUpperCase() : "UNDEFINED"}
          </Tag>
        );
      },
    },

    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "CREATED ON",
      dataIndex: "createdOn",
      key: "createdOn",
      ellipsis: true,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditCustom(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>

          <button
            onClick={() => handleDeleteCustom(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  // // Function to determine the data source based on filters and search
  // const getDataSource = () => {
  //   if (filteredData.length > 0) {
  //     // Display filtered data if filters are applied
  //     return filteredData;
  //   } else if (searchQuery) {
  //     // Display searched contacts if search query is present
  //     return searchContacts();
  //   } else {
  //     // Display all contacts by default
  //     return data;
  //   }
  // };

  // Function to determine the data source based on filters and search
  const getDataSource = () => {
    if (searchQuery) {
      // Display searched contacts if search query is present
      return searchContacts();
    } else {
      // Display all contacts by default
      return data;
    }
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Custom Field</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddCustomFieldButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add
                      Custom Field
                    </button>
                    <form className="d-flex">
                      <input
                        className="form-control me-2"
                        type="search"
                        id="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </form>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editCustomId
                                ? "Update Custom Field"
                                : "Add Custom Field"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <TextField
                                color="primary"
                                fullWidth
                                label="Title"
                                name="title"
                                value={custom.title}
                                onChange={handleChangeCustom}
                                margin="normal"
                                required
                                autoFocus
                              />
                              <TextField
                                fullWidth
                                label="Description"
                                name="description"
                                value={custom.description}
                                onChange={handleChangeCustom}
                                margin="normal"
                                required
                              />
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="type"
                                value={custom.type}
                                onChange={handleChangeCustom}
                              >
                                <option selected value="">
                                  Select Data Type
                                </option>
                                <option value="string" name="string">
                                  String
                                </option>
                                <option value="number" name="number">
                                  Number
                                </option>
                                <option value="date" name="date">
                                  Date
                                </option>
                                <option value="datetime" name="datetime">
                                  Date & Time
                                </option>
                              </select>

                              {/* Checkbox for showing the field in the contact table */}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={showInTable}
                                    onChange={(e) =>
                                      setShowInTable(e.target.checked)
                                    }
                                  />
                                }
                                label="Show this field in the contact table."
                              />
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              onClick={handleModalSave}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editCustomId
                                ? "Update Custom Field"
                                : "Add Custom Field"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  // dataSource={data}
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: tableParams.pagination.pageSize,
                    position: "bottomRight",
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "15", "20", "50", "100"],
                  }}
                  onChange={handleTableChange}
                  // pagination={{
                  //   pageSize: 5,
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customfields;
