import React, { useEffect, useState } from "react";
import axios from "axios";
import { Space, Table } from "antd";
import qs from "qs";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const TemplateSender = () => {
  const [data, setData] = useState([]);

  const [template, setTemplate] = useState({
    templateName: "",
    textItem: "",
    file: "",
    attachment: "",
    subscribe: false,
  });

  const [editTemplateId, setEditTemplateId] = useState(null);

  const handleEditTemplate = (_id) => {
    const selectedTemplate = data.find((template) => template._id === _id);

    if (selectedTemplate) {
      setEditTemplateId(_id); // Set the ID of the contact being edited
      setTemplate({
        templateName: selectedTemplate.templateName,
        textItem: selectedTemplate.textItem,
        file: selectedTemplate.file,
        attachment: selectedTemplate.attachment,
        subscribe: selectedTemplate.subscribe,
      }); // Populate the form fields with the selected contact data
    }
    // console.log(`Editing template with ID: ${_id}`);
  };

  const handleChangeTemplate = (e) => {
    const { name, value, type, checked } = e.target;
    // If the input type is a checkbox, set the value based on the checked state
    const inputValue = type === "checkbox" ? checked : value;
    setTemplate({
      ...template,
      [name]: inputValue,
    });
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const fetchTemplateData = async () => {
    try {
      const response = await axios.get(
        `/api/template/getalltemplate?${qs.stringify(
          getRandomuserParams(tableParams)
        )}`
      );
      setData(response.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: 200,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  // Fetch Contact data on component mount
  useEffect(() => {
    fetchTemplateData();
  }, [JSON.stringify(tableParams)]);

  // currently running code

  const handleSubmit = (e) => {
    e.preventDefault();

    // Determine the value for the subscribe field based on the toggle state
    const subscribeValue = template.subscribe ? true : false;

    // Combine the template data and custom fields data into a single object
    const templateData = {
      ...template,
      subscribe: subscribeValue,
    };

    if (editTemplateId) {
      // Make an HTTP POST request to send the template data to the backend
      axios
        .put(`/api/template/update/${editTemplateId}`, templateData)
        .then((response) => {
          // Clear the form inputs
          setTemplate({
            templateName: "",
            textItem: "",
            file: "",
            attachment: "",
            subscribe: "",
          });

          // Show SweetAlert2 modal with a "saved" button on success
          Swal.fire({
            title: "Template Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            // Fetch the updated template data
            fetchTemplateData();
          });
        })
        .catch((error) => {
          // Handle errors, e.g., show error message
          Swal.fire(
            "Error",
            "Error creating template. Please try again.",
            "error"
          );
        });
    } else {
      // Make an HTTP POST request to send the template data to the backend
      axios
        .post("/api/template/create", templateData)
        .then((response) => {
          // Clear the form inputs
          setTemplate({
            templateName: "",
            textItem: "",
            file: "",
            attachment: "",
            subscribe: "",
          });

          // Show SweetAlert2 modal with a "saved" button on success
          Swal.fire({
            title: "Template created successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            // Fetch the updated template data
            fetchTemplateData();
          });
        })
        .catch((error) => {
          // Handle errors, e.g., show error message
          Swal.fire(
            "Error",
            "Error creating template. Please try again.",
            "error"
          );
        });
    }
  };

  const handleDeleteTemplate = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      axios
        .delete(`/api/template/delete/${_id}`)
        .then((res) => {
          fetchTemplateData();
          Swal.fire("Deleted!", "Template deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    {
      title: "ID",
      key: "_id",
      dataIndex: "_id",
      fixed: "left",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditTemplate(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>

          <button
            onClick={() => handleDeleteTemplate(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
    {
      title: "Creation Time",
      dataIndex: "currentIndiaTime",
      key: "currentIndiaTime",
      ellipsis: true,
      render: (currentIndiaTime) => new Date(currentIndiaTime).toLocaleString(),
    },
    {
      title: "Template Name",
      dataIndex: "templateName",
      key: "templateName",
      ellipsis: true,
      render: (textItem) => (
        <div style={{ maxWidth: "200px" }}>
          {textItem
            ? textItem.length > 10
              ? `${textItem.substring(0, 11)}...`
              : textItem
            : "NA"}
        </div>
      ),
    },
    {
      title: "Text Item",
      dataIndex: "textItem",
      key: "textItem",
      ellipsis: true,
      render: (textItem) => (
        <div style={{ maxWidth: "200px" }}>
          {textItem
            ? textItem.length > 10
              ? `${textItem.substring(0, 11)}...`
              : textItem
            : "NA"}
        </div>
      ),
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ellipsis: true,
      render: (textItem) => (
        <div style={{ maxWidth: "200px" }}>
          {textItem
            ? textItem.length > 10
              ? `${textItem.substring(0, 11)}...`
              : textItem
            : "NA"}
        </div>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      ellipsis: true,
      render: (textItem) => (
        <div style={{ maxWidth: "200px" }}>
          {textItem
            ? textItem.length > 10
              ? `${textItem.substring(0, 11)}...`
              : textItem
            : "NA"}
        </div>
      ),
    },
    {
      title: "UnSubscribe Link",
      dataIndex: "subscribe",
      key: "subscribe",
      ellipsis: true,
      render: (subscribe) => (
        <span style={{ color: subscribe ? "green" : "red" }}>
          {subscribe ? "On" : "Off"}
        </span>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      textItem: prevTemplate.textItem + `<<${option}>>`,
    }));
    setAnchorEl(null); // Close the dropdown menu
  };

  const [customsData, setCustomsData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const selectedKeys = [
    "name",
    "email",
    "mobile",
    "whatsappMobile",
    "personalisedattachment",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customFieldResponse = await axios.get("/api/contact/customfield");
        const contactResponse = await axios.get("/api/contact/get");

        const extractedTitles = customFieldResponse.data.map(
          (custom) => custom.title
        );

        // Extract only the specified keys from the first contact object
        const firstContact = contactResponse.data[0];
        const extractedKeys = Object.keys(firstContact).filter((key) =>
          selectedKeys.includes(key)
        );

        setCustomsData(extractedTitles);
        setContactData(extractedKeys);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCreateNewTemplateButton = () => {
    setEditTemplateId(null);
    setTemplate({
      templateName: "",
      textItem: "",
      file: "",
      attachment: "",
      subscribe: "",
    });
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Message Templates AWS</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleCreateNewTemplateButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Create
                      New
                    </button>
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editTemplateId
                                ? "Update Message Template"
                                : "Create Message Template"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            {/* Here You can create the form */}
                            <form onSubmit={handleSubmit}>
                              <div>
                                <TextField
                                  color="primary"
                                  size="small"
                                  fullWidth
                                  label="Template Name"
                                  margin="normal"
                                  type="text"
                                  name="templateName"
                                  value={template.templateName}
                                  onChange={handleChangeTemplate}
                                  required
                                  autoFocus
                                />
                              </div>
                              {/* new fields */}
                              <div>
                                <TextField
                                  color="primary"
                                  label="Text Item"
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  type="text"
                                  name="textItem"
                                  value={template.textItem}
                                  onChange={handleChangeTemplate}
                                  InputProps={{
                                    endAdornment: (
                                      <div>
                                        <span
                                          onClick={handleClick}
                                          style={{
                                            position: "absolute",
                                            background: "none",
                                            bottom: "1px",
                                            right: "30px",
                                            fontSize: "25px",
                                            padding: "5px 10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &lt;&gt;
                                        </span>

                                        <Menu
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleClose}
                                        >
                                          {contactData.map((field, index) => (
                                            <MenuItem
                                              key={index}
                                              value={field}
                                              onClick={() =>
                                                handleOptionClick(field)
                                              }
                                            >
                                              {field}
                                            </MenuItem>
                                          ))}
                                          {customsData.map((title, index) => (
                                            <MenuItem
                                              key={index}
                                              value={title}
                                              onClick={() =>
                                                handleOptionClick(title)
                                              }
                                            >
                                              {title}
                                            </MenuItem>
                                          ))}
                                        </Menu>
                                      </div>
                                    ),
                                  }}
                                />
                              </div>

                              <div>
                                <FormControl fullWidth size="small">
                                  <InputLabel id="demo-simple-select-label">
                                    Select File
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="file"
                                    value={template.file}
                                    label="Select File"
                                    onChange={handleChangeTemplate}
                                  >
                                    <MenuItem
                                      name="attachment"
                                      value="attachment"
                                    >
                                      Attachment
                                    </MenuItem>
                                    <MenuItem
                                      name="personalizeattachment"
                                      value="personalizeattachment"
                                    >
                                      Personalize Attachment
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div>
                                <TextField
                                  color="primary"
                                  size="small"
                                  fullWidth
                                  label="Attachment (Optional)"
                                  margin="normal"
                                  type="text"
                                  name="attachment"
                                  value={template.attachment}
                                  onChange={handleChangeTemplate}
                                  autoFocus
                                />
                              </div>
                              <div>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="subscribe"
                                    checked={template.subscribe}
                                    onChange={handleChangeTemplate}
                                    id="flexSwitchCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                  >
                                    Unsubscribe
                                  </label>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  data-bs-dismiss="modal"
                                  className="btn btn-primary"
                                >
                                  {editTemplateId ? "Update " : "Create "}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: "5" }}
                  style={{ overflowX: data.length > 5 ? "scroll" : "hidden" }} // Show scrollbar only if data is lengthy
                  pagination={{
                    pageSize: tableParams.pagination.pageSize, // Set the page size
                    position: "bottomRight", // Optionally place the pagination controls at the bottom right corner
                    showSizeChanger: true, // Enable the "Rows per page" selection
                    pageSizeOptions: ["5", "10", "15", "20"], // Specify available page sizes
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateSender;
