import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table, Button } from "antd";
import moment from "moment";
import { Box, TextField, Grid } from "@mui/material";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const FutureWebinar = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFutureWebinarId, setEditFutureWebinarId] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [nextCustomFieldType, setNextCustomFieldType] = useState("");
  const [customsData, setCustomsData] = useState([]);
  const [futurewebinar, setFutureWebinar] = useState({
    programname: "",
    webinardate: "",
    eventtype: "",
    webinarid: "",
    wagrouplink: "",
    template: "",
    webinarregistrationlink: "",
    futurewebinarlink: "", // Added futurewebinarlink
    transitiontime: "",
  });

  // Function to convert local date and time to IST string
  const toISTString = (localDateTime) => {
    return moment(localDateTime)
      .utcOffset("+05:30")
      .format("YYYY-MM-DDTHH:mm:ss");
  };

  const isValidURL = (url) => {
    // Regular expression to validate URL format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  // Function to handle date picker change and store IST string
  const handleDatePickerChange = (dateTimeString) => {
    setFutureWebinar({
      ...futurewebinar,
      webinardate: toISTString(dateTimeString),
    });
  };

  // Function to handle transition time picker change and store IST string
  const handleDatePickertransitiontimeChange = (dateTimeString) => {
    setFutureWebinar({
      ...futurewebinar,
      transitiontime: toISTString(dateTimeString),
    });
  };

  const fetchFutureWebinarData = async () => {
    try {
      const response = await axios.get("/api/futurewebinar/future");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomsData = async () => {
    try {
      const response = await axios.get("/api/contact/customfield");
      setCustomsData(response.data);
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  useEffect(() => {
    fetchFutureWebinarData();
    fetchCustomsData();
  }, []);

  const handleChangeFutureWebinar = (e) => {
    const { name, value } = e.target;
    setFutureWebinar({
      ...futurewebinar,
      [name]: value,
    });
  };

  const handleAddFutureWebinar = (e) => {
    e.preventDefault();

    const futurewebinarData = {
      ...futurewebinar,
      customFields: customFields,
    };

    if (editFutureWebinarId) {
      axios
        .put(
          `/api/futurewebinar/update/${editFutureWebinarId}`,
          futurewebinarData
        )
        .then((res) => {
          setFutureWebinar({
            programname: "",
            webinardate: "",
            eventtype: "",
            webinarid: "",
            wagrouplink: "",
            template: "",
            webinarregistrationlink: "",
            futurewebinarlink: "",
            transitiontime: "",
          });
          setCustomFields([]);
          Swal.fire({
            title: "Webinar Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchFutureWebinarData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webinar. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/futurewebinar/create", futurewebinarData)
        .then((res) => {
          setFutureWebinar({
            programname: "",
            webinardate: "",
            eventtype: "",
            webinarid: "",
            wagrouplink: "",
            template: "",
            webinarregistrationlink: "",
            futurewebinarlink: "",
            transitiontime: "",
          });
          setCustomFields([]);
          Swal.fire({
            title: "Webinar Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchFutureWebinarData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webinar. Please try again.",
            "error"
          );
        });
    }
  };

  const handleDeleteFutureWebinar = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/futurewebinar/delete/${_id}`)
        .then((res) => {
          fetchFutureWebinarData();
          Swal.fire("Deleted!", "Webinar deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditFutureWebinar = (_id) => {
    const selectedFutureWebinar = data.find((fwid) => fwid._id === _id);

    if (selectedFutureWebinar) {
      setEditFutureWebinarId(_id);
      setFutureWebinar({
        programname: selectedFutureWebinar.programname,
        webinardate: selectedFutureWebinar.webinardate
          ? moment(selectedFutureWebinar.webinardate).format("YYYY-MM-DDTHH:mm")
          : "",
        eventtype: selectedFutureWebinar.eventtype,
        webinarid: selectedFutureWebinar.webinarid,
        wagrouplink: selectedFutureWebinar.wagrouplink,
        template: selectedFutureWebinar.template,
        webinarregistrationlink: selectedFutureWebinar.webinarregistrationlink,
        futurewebinarlink: selectedFutureWebinar.futurewebinarlink, // Added futurewebinarlink
        transitiontime: selectedFutureWebinar.transitiontime
          ? moment(selectedFutureWebinar.transitiontime).format(
              "YYYY-MM-DDTHH:mm"
            )
          : "",
      });
      setCustomFields(
        selectedFutureWebinar.customFields.map((field) => ({
          title: field.title,
          value: field.value,
        }))
      );
    }
    setIsModalOpen(true);
  };

  const handleAddFutureWebinarButton = () => {
    setEditFutureWebinarId(null);
    setFutureWebinar({
      programname: "",
      webinardate: "",
      eventtype: "",
      webinarid: "",
      wagrouplink: "",
      template: "",
      webinarregistrationlink: "",
      futurewebinarlink: "",
      transitiontime: "",
    });
    setCustomFields([]);
  };

  const handleAddCustomField = () => {
    const selectedCustom = customsData.find(
      (custom) => custom.title === nextCustomFieldType
    );

    if (selectedCustom) {
      setCustomFields([
        ...customFields,
        { title: selectedCustom.title, value: "" },
      ]);
      setNextCustomFieldType("");
    }
  };

  const handleCustomFieldValueChange = (index, event) => {
    const updatedFields = [...customFields];
    updatedFields[index].value = event.target.value;
    setCustomFields(updatedFields);
  };

  const handleRemoveCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: "PROG. NAME",
      dataIndex: "programname",
      key: "programname",
      ellipsis: true,
    },
    {
      title: "Webinar Date",
      dataIndex: "webinardate",
      key: "webinardate",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },

    {
      title: "Event Type",
      dataIndex: "eventtype",
      key: "eventtype",
      ellipsis: true,
    },
    {
      title: "Webinar Id",
      dataIndex: "webinarid",
      key: "webinarid",
      ellipsis: true,
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      ellipsis: true,
    },
    {
      title: "WaGroupLink",
      dataIndex: "wagrouplink",
      key: "wagrouplink",
      ellipsis: true,
    },
    {
      title: "Web. Reg. Link",
      dataIndex: "webinarregistrationlink",
      key: "webinarregistrationlink",
      ellipsis: true,
    },
    {
      title: "Transition time",
      dataIndex: "transitiontime",
      key: "transitiontime",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Future Webinar ID",
      dataIndex: "futurewebinarlink",
      key: "futurewebinarlink",
      ellipsis: true,
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditFutureWebinar(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteFutureWebinar(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Future Webinar</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddFutureWebinarButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Future
                      Webinar
                    </button>

                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editFutureWebinarId
                                ? "Update Webinar"
                                : "Add Webinar"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <TextField
                                size="small"
                                color="primary"
                                fullWidth
                                label="Program Name"
                                name="programname"
                                value={futurewebinar.programname}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                              />
                              <h6>Webinar Date</h6>
                              <input
                                type="datetime-local"
                                id="webinardate"
                                name="webinardate"
                                value={futurewebinar.webinardate}
                                onChange={(e) =>
                                  handleDatePickerChange(e.target.value)
                                }
                                className="form-select w-100"
                              />

                              {/* <DatePicker
                                showTime
                                id="webinardate"
                                name="webinardate"
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Select Date and Time"
                                value={
                                  futurewebinar.webinardate
                                    ? moment(futurewebinar.webinardate)
                                    : null
                                }
                                onChange={(date, dateString) =>
                                  handleDatePickerChange(dateString)
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                style={{ width: "100%" }}
                              /> */}

                              {/* <TextField
                                color="primary"
                                fullWidth
                                label="Event Type"
                                name="eventtype"
                                value={futurewebinar.eventtype}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                              /> */}

                              {/* Event Type Selector */}
                              <select
                                style={{ marginTop: "15px" }}
                                className="form-select"
                                aria-label="Default select example"
                                name="eventtype"
                                value={futurewebinar.eventtype}
                                onChange={handleChangeFutureWebinar}
                              >
                                <option selected value="" disabled>
                                  --`Select Event Type--
                                </option>
                                <option value="zoom meeting" name="whatsapp">
                                  Zoom meeting
                                </option>
                                <option value="zoom webinar" name="facebook">
                                  Zoom Webinar
                                </option>
                                <option value="ever webinar" name="instagram">
                                  Ever Webinar
                                </option>
                                <option value="webinar jam" name="instagram">
                                  Webinar Jam
                                </option>
                              </select>

                              <TextField
                                size="small"
                                color="primary"
                                fullWidth
                                label="Webinar Id"
                                name="webinarid"
                                value={futurewebinar.webinarid}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                              />
                              <TextField
                                size="small"
                                color="primary"
                                fullWidth
                                label="Template"
                                name="template"
                                value={futurewebinar.template}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                              />
                              <TextField
                                size="small"
                                color="primary"
                                fullWidth
                                label="Wagroup link"
                                name="wagrouplink"
                                value={futurewebinar.wagrouplink}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                                error={
                                  futurewebinar.wagrouplink &&
                                  !isValidURL(futurewebinar.wagrouplink)
                                }
                                helperText={
                                  futurewebinar.wagrouplink &&
                                  !isValidURL(futurewebinar.wagrouplink) &&
                                  "Please enter a valid URL"
                                }
                              />
                              <TextField
                                size="small"
                                color="primary"
                                fullWidth
                                label="Webinar Registration Link"
                                name="webinarregistrationlink"
                                value={futurewebinar.webinarregistrationlink}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                                error={
                                  futurewebinar.webinarregistrationlink &&
                                  !isValidURL(
                                    futurewebinar.webinarregistrationlink
                                  )
                                }
                                helperText={
                                  futurewebinar.webinarregistrationlink &&
                                  !isValidURL(
                                    futurewebinar.webinarregistrationlink
                                  ) &&
                                  "Please enter a valid URL"
                                }
                              />
                              <TextField
                                size="small"
                                color="primary"
                                fullWidth
                                label="Future Webinar ID"
                                name="futurewebinarlink"
                                value={futurewebinar.futurewebinarlink}
                                onChange={handleChangeFutureWebinar}
                                margin="normal"
                                autoFocus
                              />
                              <h6>Transition Time</h6>
                              <input
                                type="datetime-local"
                                id="transitiontime"
                                name="transitiontime"
                                value={futurewebinar.transitiontime}
                                onChange={(e) =>
                                  handleDatePickertransitiontimeChange(
                                    e.target.value
                                  )
                                }
                                className="form-select w-100"
                              />
                              {/* <DatePicker
                                showTime
                                id="transitiontime"
                                name="transitiontime"
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Select Date and Time"
                                value={
                                  futurewebinar.transitiontime
                                    ? moment(futurewebinar.transitiontime)
                                    : null
                                }
                                onChange={(date, dateString) =>
                                  handleDatePickertransitiontimeChange(
                                    dateString
                                  )
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                style={{ width: "100%" }}
                              /> */}
                              <div>
                                {customFields.map((field, index) => (
                                  <div key={index}>
                                    <Grid
                                      container
                                      spacing={1}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Grid item xs={11}>
                                        <TextField
                                          size="small"
                                          fullWidth
                                          label={`${field.title}`}
                                          type="text"
                                          margin="normal"
                                          value={field.value}
                                          onChange={(e) =>
                                            handleCustomFieldValueChange(
                                              index,
                                              e
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <MinusCircleOutlined
                                          style={{
                                            fontSize: "25px",
                                            textAlign: "center",
                                          }}
                                          onClick={() =>
                                            handleRemoveCustomField(index)
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Grid item xs={9}>
                                    <select
                                      className="form-select"
                                      margin="normal"
                                      value={nextCustomFieldType}
                                      onChange={(e) =>
                                        setNextCustomFieldType(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        Select a Custom Field
                                      </option>
                                      {customsData.map((custom) => (
                                        <option
                                          key={custom._id}
                                          value={custom.title}
                                        >
                                          {custom.title}
                                        </option>
                                      ))}
                                    </select>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      type="dashed"
                                      onClick={handleAddCustomField}
                                      icon={<PlusOutlined />}
                                    >
                                      Add field
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddFutureWebinar}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editFutureWebinarId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureWebinar;
