import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";

const Segments = () => {
  const [data, setData] = useState([]);
  const [editSegmentId, setEditSegmentId] = useState(null);

  const [segment, setSegment] = useState({
    title: "",
    description: "",
    field: "",
    condition: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customsData, setCustomsData] = useState([]);
  const [contactData, setContactData] = useState([]);

  const selectedKeys = [
    "name",
    "email",
    "mobile",
    "whatsappMobile",
    "personalisedattachment",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customFieldResponse = await axios.get("/api/contact/customfield");
        const contactResponse = await axios.get("/api/contact/getcontact");

        const extractedTitles = customFieldResponse.data.map(
          (custom) => custom.title
        );

        // Extract only the specified keys from the first contact object
        const firstContact = contactResponse.data[0];
        const extractedKeys = Object.keys(firstContact).filter((key) =>
          selectedKeys.includes(key)
        );
        setCustomsData(extractedTitles);
        setContactData(extractedKeys);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const fetchSegmentsData = async () => {
    try {
      const response = await axios.get("/api/segment/get");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSegmentsData();
  }, []);

  const handleEditSegment = (_id) => {
    const selectedSegment = data.find((segmentid) => segmentid._id === _id);

    if (selectedSegment) {
      setEditSegmentId(_id); // Set the ID of the contact being edited
      setSegment({
        title: selectedSegment.title,
        description: selectedSegment.description,
        field: selectedSegment.field,
        condition: selectedSegment.condition,
      }); // Populate the form fields with the selected contact data
    }

    setIsModalOpen(true);

    // console.log(`Editing Segment with ID: ${_id}`);
  };

  const handleChangeSegment = (e) => {
    const { name, value } = e.target;
    const updatedData = {
      ...segment,
      [name]: value,
    };
    setSegment(updatedData);
  };

  const handleDeleteSegment = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Delete contact with the given ID
      axios
        .delete(`/api/segment/delete/${_id}`)
        .then((res) => {
          // toast.success("Custom Field deleted successfully!");
          fetchSegmentsData();
          Swal.fire("Deleted!", "Segment deleted successfully!", "success");
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleSegment = (e) => {
    e.preventDefault();

    const segmentsData = {
      ...segment,
    };

    if (editSegmentId) {
      axios
        .put(`/api/segment/edit/${editSegmentId}`, segmentsData)
        .then((res) => {
          setSegment({ title: "", description: "", field: "", condition: "" });
          Swal.fire({
            title: "Segment Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchSegmentsData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error updating Segment. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/segment/create", segmentsData)
        .then((res) => {
          setSegment({ title: "", description: "", field: "", condition: "" });
          Swal.fire({
            title: "Segment Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchSegmentsData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating segment. Please try again.",
            "error"
          );
        });
    }
  };

  const handleAddNewSegment = () => {
    setEditSegmentId(null);
    setSegment({
      title: "",
      description: "",
      field: "",
      condition: "",
    });
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: "CONDITIONS",
      dataIndex: "condition",
      key: "condition",
      ellipsis: true,
    },
    {
      title: "CONTACTS",
      dataIndex: "whatsappNumbers",
      key: "whatsappNumbers",
      ellipsis: true,
      render: (whatsappNumbers) => {
        const count = whatsappNumbers.length;
        let colorClass = "";
        if (count < 5) {
          colorClass = "text-danger";
        } else if (count < 10) {
          colorClass = "text-warning";
        } else {
          colorClass = "text-success";
        }
        return <span className={colorClass}>{count}</span>;
      },
    },
    {
      title: "CREATED ON",
      dataIndex: "createdOn",
      key: "createdOn",
      ellipsis: true,
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },

    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditSegment(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>

          <button
            onClick={() => handleDeleteSegment(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Segments</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <!-- Button trigger modal --> */}
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddNewSegment}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Add New
                      Segment
                    </button>

                    {/* <!-- Modal --> */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editSegmentId
                                ? "Update New Segment"
                                : "Add New Segment"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              <div style={{ display: "flex", gap: "20px" }}>
                                <div className="w-100">
                                  <TextField
                                    color="primary"
                                    fullWidth
                                    label="Segment Title"
                                    name="title"
                                    size="small"
                                    value={segment.title}
                                    onChange={handleChangeSegment}
                                    margin="normal"
                                    required
                                    autoFocus
                                  />
                                </div>
                                <div className="w-100">
                                  <TextField
                                    fullWidth
                                    label="List Description"
                                    name="description"
                                    size="small"
                                    value={segment.description}
                                    onChange={handleChangeSegment}
                                    margin="normal"
                                    required
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex", gap: "20px" }}>
                                <div className="w-100">
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="field"
                                    value={segment.field}
                                    onChange={handleChangeSegment}
                                  >
                                    <option value="" disabled selected>
                                      Select Field
                                    </option>
                                    <optgroup label="SYSTEM FIELDS">
                                      {contactData.map((field, index) => (
                                        <option key={index} value={field}>
                                          {field}
                                        </option>
                                      ))}
                                    </optgroup>
                                    <optgroup label="CUSTOM FIELDS">
                                      {customsData.map((title, index) => (
                                        <option key={index} value={title}>
                                          {title}
                                        </option>
                                      ))}
                                    </optgroup>
                                  </select>
                                </div>
                                <div className="w-100">
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="condition"
                                    value={segment.condition}
                                    onChange={handleChangeSegment}
                                  >
                                    <option value="" disabled selected>
                                      Select Conditions
                                    </option>
                                    <optgroup label="GENERAL CONDITIONS">
                                      {/* <option value="isnull" name="isnull">
                                        is null
                                      </option>
                                      <option
                                        value="isnotnull"
                                        name="isnotnull"
                                      >
                                        is not null
                                      </option> */}
                                      <option value="exist" name="exist">
                                        exist
                                      </option>
                                      <option
                                        value="doesnotexist"
                                        name="doesnotexist"
                                      >
                                        does not exist
                                      </option>
                                    </optgroup>
                                    {/* <optgroup label="CONDITIONS FOR TEXT">
                                      <option value="is" name="is">
                                        is
                                      </option>
                                      <option value="isnot" name="isnot">
                                        is not
                                      </option>
                                      <option
                                        value="startwith"
                                        name="startwith"
                                      >
                                        start with
                                      </option>
                                      <option value="endwith" name="endwith">
                                        end with
                                      </option>
                                      <option value="isempty" name="isempty">
                                        is empty
                                      </option>
                                      <option
                                        value="isnotempty"
                                        name="isnotempty"
                                      >
                                        is not empty
                                      </option>
                                      <option value="contains" name="contains">
                                        contains
                                      </option>
                                      <option
                                        value="doesnotcontains"
                                        name="doesnotcontains"
                                      >
                                        does not contains
                                      </option>
                                    </optgroup> */}
                                  </select>
                                </div>
                              </div>
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleSegment}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editSegmentId ? "Update Segment" : "Add Segment"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  style={{
                    overflowX: data.length > 5 ? "scroll" : "hidden",
                  }}
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Segments;
