import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";

const ExpiredWebinar = () => {
  const [currentWebinars, setCurrentWebinars] = useState(null);

  useEffect(() => {
    const fetchWebinarData = async () => {
      try {
        const response = await axios.get("/api/futurewebinar/expire");
        const webinarData = response.data;
        setCurrentWebinars(webinarData);
      } catch (error) {
        console.error("Error fetching webinar data:", error);
      }
    };

    fetchWebinarData();

    // Fetch updated webinar data every 60 seconds
    const interval = setInterval(fetchWebinarData, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleDeleteFutureWebinar = async (_id) => {
    try {
      // Show SweetAlert2 confirm dialog
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmed.isConfirmed) {
        // Show a loading spinner while holding the action
        Swal.fire({
          title: "Deleting...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        // Perform delete operation
        await axios.delete(`/api/futurewebinar/delete/${_id}`);
        // Remove the deleted webinar from the UI
        setCurrentWebinars(
          currentWebinars.filter((webinar) => webinar._id !== _id)
        );
        // Show success message
        Swal.fire("Deleted!", "This Webinar deleted successfully!", "success");
      }
    } catch (error) {
      console.error("Error deleting webinar:", error);
      Swal.fire(
        "Error",
        "Something went wrong while deleting the webinar!",
        "error"
      );
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },

    {
      title: "PROG. NAME",
      dataIndex: "programname",
      key: "programname",
      ellipsis: true,
    },
    {
      title: "Webinar Date",
      dataIndex: "webinardate",
      key: "webinardate",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Transition Time",
      dataIndex: "transitiontime",
      key: "transitiontime",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Event Type",
      dataIndex: "eventtype",
      key: "eventtype",
      ellipsis: true,
    },
    {
      title: "Webinar Id",
      dataIndex: "webinarid",
      key: "webinarid",
      ellipsis: true,
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      ellipsis: true,
    },
    {
      title: "WaGroupLink",
      dataIndex: "wagrouplink",
      key: "wagrouplink",
      ellipsis: true,
    },
    {
      title: "web. reg. Link",
      dataIndex: "webinarregistrationlink",
      key: "webinarregistrationlink",
      ellipsis: true,
    },
    {
      title: "STATUS",
      key: "status",
      width: 150,

      render: (text, record) => {
        // Check if the current webinar is ongoing, upcoming, or expired
        const webinarDate = new Date(record.webinardate);
        const currentDate = new Date();
        if (webinarDate < currentDate) {
          return <span style={{ color: "red" }}>Expired</span>;
        } else if (webinarDate > currentDate) {
          return <span style={{ color: "blue" }}>Upcoming</span>;
        } else {
          return <span style={{ color: "green" }}>Ongoing</span>;
        }
      },
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,

      render: (text, record) => (
        <Space size="middle">
          <button
            onClick={() => handleDeleteFutureWebinar(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Expired Webinar History</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* Add button */}
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={currentWebinars}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredWebinar;
