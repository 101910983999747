import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import qs from "qs";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";

import Swal from "sweetalert2";

function LoadingModal({ progress }) {
  return (
    <div
      className="loading-modal"
      style={{
        display: "flex",
        background: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(5px)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1050,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="spinner-border mb-3"
        role="status"
        style={{ color: "#fff" }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

const DTMF_Campaign = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [numRecords, setNumRecords] = useState("");
  const [batch, setBatch] = useState("");
  const [startDate, setStartDate] = useState(""); // Add state for start date
  const [endDate, setEndDate] = useState(""); // Add state for end date
  const [contactIds, setContactIds] = useState([]);

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const params = {
        startreq:
          (tableParams.pagination.current - 1) *
          tableParams.pagination.pageSize,
        limit: tableParams.pagination.pageSize,
      };

      const response = await axios.get(
        `/api/campaign/getcontactsforcampaign?${qs.stringify(params)}`
      );
      setData(response.data.contacts);
      setTotal(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleDownload = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to download all contacts.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, download it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDownloadLoading(true);
        axios
          .get("/api/campaign/download", { responseType: "blob" })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "contacts.zip");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            toast.success("File downloaded successfully!");
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
            toast.error("Error downloading the file.");
          })
          .finally(() => setDownloadLoading(false));
      }
    });
  };

  // const handleRandomDownload = async () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You are about to download ${numRecords} records in batches of ${batch}.`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, download it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setDownloadLoading(true);
  //       axios
  //         .get(`/api/campaign/random?numRecords=${numRecords}&batch=${batch}`, {
  //           responseType: "blob",
  //           onDownloadProgress: (progressEvent) => {
  //             const { loaded, total } = progressEvent;
  //             if (total) {
  //               const percentCompleted = Math.round((loaded * 100) / total);
  //               setDownloadProgress(percentCompleted);
  //             }
  //           },
  //         })
  //         .then((response) => {
  //           const url = window.URL.createObjectURL(new Blob([response.data]));
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", "randomcontacts.csv");
  //           document.body.appendChild(link);
  //           link.click();
  //           link.parentNode.removeChild(link);
  //           // Assume `response.data.contactIds` contains the array of IDs
  //           const contactIds = response.data.contactIds;
  //           Swal.fire({
  //             title: "Update DTMF Campaign?",
  //             text: "Do you want to update the DTMF campaign for the downloaded data?",
  //             icon: "question",
  //             showCancelButton: true,
  //             confirmButtonColor: "#3085d6",
  //             cancelButtonColor: "#d33",
  //             confirmButtonText: "Yes, update it!",
  //           }).then((updateResult) => {
  //             if (updateResult.isConfirmed) {
  //               console.log("Contact IDs:", contactIds);
  //               axios
  //                 .post("/api/campaign/updatedtmf", { contactIds })
  //                 .then(() => {
  //                   toast.success("Contacts updated successfully!");
  //                   fetchContactData(); // Refresh data if needed
  //                 })
  //                 .catch((error) => {
  //                   console.error("Error updating contacts:", error);
  //                   toast.error("Error updating contacts.");
  //                 });
  //             }
  //           });
  //         })
  //         .catch((error) => {
  //           console.error("Error downloading the file:", error);
  //           toast.error("Error downloading the random contacts.");
  //         })
  //         .finally(() => setDownloadLoading(false));
  //     }
  //   });
  // };

  // Function to parse CSV data and extract contact IDs

  const handleRandomDownload = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to download ${numRecords} records in batches of ${batch}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, download it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDownloadLoading(true);
        axios
          .get(`/api/campaign/random?numRecords=${numRecords}&batch=${batch}`, {
            responseType: "json", // Expect JSON response
          })
          .then((response) => {
            const { csvFile, contactIds } = response.data;

            if (contactIds && contactIds.length > 0) {
              // Create a download link for the CSV file
              const url = window.URL.createObjectURL(new Blob([csvFile]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "randomcontacts.csv");
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);

              // Prompt to update DTMF campaign
              Swal.fire({
                title: "Update DTMF Campaign?",
                text: "Do you want to update the DTMF campaign for the downloaded data?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, update it!",
              }).then((updateResult) => {
                if (updateResult.isConfirmed) {
                  axios
                    .post("/api/campaign/updatedtmf", { contactIds })
                    .then(() => {
                      toast.success("Contacts updated successfully!");
                      fetchContactData(); // Refresh data if needed
                    })
                    .catch((error) => {
                      console.error("Error updating contacts:", error);
                      toast.error("Error updating contacts.");
                    });
                }
              });
            } else {
              console.error("No contact IDs found in the response.");
              toast.error("No contact IDs found.");
            }
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
            toast.error("Error downloading the random contacts.");
          })
          .finally(() => setDownloadLoading(false));
      }
    });
  };

  const handleFilterDownload = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to download contacts between ${startDate} and ${endDate}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, download it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDownloadLoading(true);
        axios
          .get(`/api/campaign/date`, {
            responseType: "blob",
            params: {
              startDate,
              endDate,
            },
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              if (total) {
                const percentCompleted = Math.round((loaded * 100) / total);
                setDownloadProgress(percentCompleted);
              }
            },
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "filteredcontacts.zip");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            toast.success("Filtered contacts downloaded successfully!");
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
            toast.error("Error downloading the filtered contacts.");
          })
          .finally(() => setDownloadLoading(false));
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
    },
    {
      title: "Flag",
      dataIndex: "flag",
      key: "flag",
      ellipsis: true,
    },
  ];

  const getDataSource = () => {
    return data;
  };

  const handleNumRecordsChange = (e) => {
    setNumRecords(e.target.value);
  };

  const handleBatchChange = (e) => {
    setBatch(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        {downloadLoading && <LoadingModal progress={downloadProgress} />}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">DTMF Campaign</h4>
              <div>
                <h4 className="mb-sm-0 py-3" style={{ color: "#EE0C5D" }}>
                  Fresh Data : {total}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2"></div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-danger add-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#filterModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Filter
                    </button>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exportModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Export
                      Data
                    </button>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="primary"
                      className="btn btn-success"
                      onClick={handleDownload}
                    >
                      Download All
                    </button>
                  </div>
                  {/* Filter Modal */}
                  <div
                    className="modal fade"
                    id="filterModal"
                    tabIndex="-1"
                    aria-labelledby="filterModalLabel"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="filterModalLabel">
                            Filter Modal
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <Grid
                            container
                            spacing={1}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Grid item xs={6}>
                              <div>
                                <label htmlFor="startDate">From</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                <label htmlFor="endDate">To</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="modal-footer">
                          <button
                            onClick={handleFilterDownload}
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Export Random Data Modal */}
                  <div
                    className="modal fade"
                    id="exportModal"
                    tabIndex="-1"
                    aria-labelledby="exportModalLabel"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exportModalLabel">
                            Export Data
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <Grid
                            container
                            spacing={1}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Grid item xs={6}>
                              <div>
                                <label htmlFor="randomcount">No. of Data</label>
                                <select
                                  className="form-select mt-2"
                                  name="randomcount"
                                  id="randomcount"
                                  value={numRecords}
                                  onChange={handleNumRecordsChange}
                                >
                                  <option value="...">...</option>
                                  <option value="10000">10K</option>
                                  <option value="20000">20K</option>
                                  <option value="30000">30K</option>
                                  <option value="40000">40K</option>
                                  <option value="50000">50K</option>
                                  <option value="100000">100K</option>
                                </select>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                <label htmlFor="batch">Batch</label>
                                <select
                                  className="form-select mt-2"
                                  name="batch"
                                  id="batch"
                                  value={batch}
                                  onChange={handleBatchChange}
                                >
                                  <option value="...">...</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2000">2000</option>
                                  <option value="5000">5000</option>
                                  <option value="10000">10000</option>
                                </select>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="modal-footer">
                          <button
                            onClick={handleRandomDownload}
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  pagination={{
                    ...tableParams.pagination,
                    total,
                    position: "bottomRight",
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "15", "20", "50", "100"],
                  }}
                  onChange={handleTableChange}
                  loading={loading}
                  bordered
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DTMF_Campaign;
