import React, { useEffect, useState } from "react";
import axios from "axios";
import { Space, Table, Spin, notification } from "antd";
import { Box } from "@mui/material";
import "../styles/TemplatesPage.css";

const TemplatesVtwo = () => {
  const [data, setData] = useState([]);
  const [messageTemplate, setMessageTemplate] = useState("");
  const [jsonTemplate, setJsonTemplate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/whatsappv2/gettemplate");

        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        notification.error({
          message: "Error",
          description: "Failed to fetch templates. Please try again later.",
        });
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(data);

  // Mapping of language codes to their full forms
  const languageMapping = {
    en: "English",
    fr: "French",
    es: "Spanish",
    de: "German",
    it: "Italian",
    pt: "Portuguese",
    zh: "Chinese",
    ja: "Japanese",
    ko: "Korean",
    ru: "Russian",
    ar: "Arabic",
    hi: "Hindi",
    bn: "Bengali",
    ur: "Urdu",
    pa: "Punjabi",
    gu: "Gujarati",
    te: "Telugu",
    ta: "Tamil",
    ml: "Malayalam",
    th: "Thai",
    vi: "Vietnamese",
    nl: "Dutch",
    pl: "Polish",
    sv: "Swedish",
    da: "Danish",
    no: "Norwegian",
    fi: "Finnish",
    el: "Greek",
    tr: "Turkish",
    he: "Hebrew",
    id: "Indonesian",
    ms: "Malay",
    fil: "Filipino",
    hu: "Hungarian",
    cs: "Czech",
    sk: "Slovak",
    ro: "Romanian",
    uk: "Ukrainian",
    hr: "Croatian",
    sr: "Serbian",
    sl: "Slovenian",
    bg: "Bulgarian",
    lt: "Lithuanian",
    lv: "Latvian",
    et: "Estonian",
    is: "Icelandic",
    ga: "Irish",
    cy: "Welsh",
    sq: "Albanian",
    mk: "Macedonian",
    bs: "Bosnian",
    mt: "Maltese",
    // Add more language codes and their full forms as needed
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "APPROVED":
        return <span style={{ color: "green" }}>{status}</span>;
      case "PENDING":
        return <span style={{ color: "orange" }}>{status}</span>;
      case "REJECTED":
        return <span style={{ color: "red" }}>{status}</span>;
      default:
        return <span>{status}</span>;
    }
  };

  const getMessageTemplate = (template) => {
    const components = template.components;
    let message = "<div class='whatsapp-message'>";

    components.forEach((component) => {
      switch (component.type) {
        case "BODY":
          const example = component.example.body_text[0];
          let bodyText = component.text;

          if (Array.isArray(example)) {
            example.forEach((placeholder, index) => {
              const placeholderRegex = new RegExp(`{{${index + 1}}}`, "g");
              bodyText = bodyText.replace(placeholderRegex, placeholder);
            });
          }

          message += `<div class="message-body">${bodyText}</div>`;
          break;
        case "FOOTER":
          message += `<footer class="message-footer">${component.text}</footer>`;
          break;
        case "BUTTONS":
          message += "<div class='message-buttons'>";
          component.buttons.forEach((button, index) => {
            if (button.type === "URL") {
              const example = button.example[0];
              let buttonText = button.text;

              if (Array.isArray(example)) {
                example.forEach((placeholder, index) => {
                  const placeholderRegex = new RegExp(`{{${index + 1}}}`, "g");
                  buttonText = buttonText.replace(
                    placeholderRegex,
                    placeholder
                  );
                });
              }

              message += `<a href="${button.url}" class="message-button" key="${index}" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">${buttonText}</a>`;
            } else if (button.type === "QUICK_REPLY") {
              message += `<button class="quick-reply" key="${index}">${button.text}</button>`;
            }
          });
          message += "</div>";
          break;
        default:
          break;
      }
    });

    message += "</div>";
    return message;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      render: (language) => languageMapping[language] || language,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status) => getStatusColor(status),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalJson"
            className="edit-btn"
            onClick={() => handleShowJsonFormat(record)}
          >
            <i class="ri-code-s-fill"></i>
          </button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleViewTemplate(record)}
            aria-label="View Template"
          >
            <i className="ri-eye-line"></i>
          </button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            // onClick={() => handleViewTemplate(record)}
            aria-label="Test Template"
          >
            <i class="ri-send-plane-fill"></i>
          </button>
        </Space>
      ),
    },
  ];

  const handleViewTemplate = (template) => {
    try {
      const messageTemplate = getMessageTemplate(template);
      setMessageTemplate(messageTemplate);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description: "Failed to fetch template data. Please try again later.",
      });
    }
  };

  const handleShowJsonFormat = async (templateId) => {
    try {
      setJsonTemplate(templateId);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description: "Failed to fetch template data. Please try again later.",
      });
    }
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="mb-0 py-3">Templates</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Message Template
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body whatsapp-modal-content">
                            <Box>
                              {messageTemplate && (
                                <div
                                  className="message-template"
                                  dangerouslySetInnerHTML={{
                                    __html: messageTemplate,
                                  }}
                                />
                              )}
                            </Box>
                          </div>
                          {/* <div className="modal-footer"></div> */}
                        </div>
                      </div>
                    </div>
                    {/* JSON Modal */}
                    <div
                      className="modal fade"
                      id="exampleModalJson"
                      tabIndex="-1"
                      aria-labelledby="exampleModalJsonLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="exampleModalJsonLabel"
                            >
                              JSON Format
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <pre>
                              {jsonTemplate &&
                                JSON.stringify(jsonTemplate, null, 2)}
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <div className="loading-spinner">
                    <Spin />
                  </div>
                ) : (
                  <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="_id"
                    pagination={{ pageSize: 5 }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatesVtwo;
